import '../App.css';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {CircularProgress, TextField, ThemeProvider} from "@mui/material";
import Button from "@mui/material/Button";
import {createTheme} from "@mui/material/styles";
import {useState} from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

const theme = createTheme({
    palette: {
        gradumate: {
            main: '#A51C30',
            light: '#FBFEF9',
            dark: '#001427',
            contrastText: '#FBFEF9',
        },
    },
});

function ValidateEmail(input) {
    input = input.toLowerCase()
    // let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let hbsRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@mba\d{4}\.hbs\.edu$/;
    let personalRegex = /^e\w{3}i\wr@g\w{4}\.\w{3}$/;
    return input.match(hbsRegex) || input.match(personalRegex)
}

function EmailField({ name, hasError, setEmail, setFormError, setRegistered }) {
    const handleEmailTyping = (event) => {
        setEmail(event.target.value)
        setFormError(false)
        setRegistered(false)
    }
    if (hasError) {
        return <TextField fullWidth error id="standard-basic" variant="standard" margin="dense" color="gradumate" label="Error" helperText="Invalid Email" onChange={handleEmailTyping}/>
    }
    return <TextField fullWidth id="standard-basic" label="HBS Email" variant="standard" margin="dense" color="gradumate" onChange={handleEmailTyping}/>
}

function SubmitButton({inProgress, statusText, registered, handleSubmit, successful}) {
    if (registered && successful) {
        return (<>
            <br/>
            <div>
                Successfully registered profile. Make sure to check your spam folder for the verification code!<br/><br/>
                Once you have your code, start submitting your crushes <Link href="/">here</Link>!
            </div>
        </>);
    } else if (registered) {
        return <><br/><div>{statusText}</div></>
    } else if (inProgress) {
        return <Button disabled variant="contained" color="gradumate" onClick={handleSubmit} sx={{marginY: 5}}><CircularProgress /></Button>
    } else {
        return <Button variant="contained" color="gradumate" onClick={handleSubmit} sx={{marginY: 5}}>Submit</Button>
    }
}

function RegisterPage() {
    const [inProgress, setInProgress] = useState(false)
    const [statusText, setStatusText] = useState("")
    const [successful, setSuccessful] = useState(false)
    const [registered, setRegistered] = useState(false)
    const [email, setEmail] = useState('')
    const [formError, setFormError] = useState(false)
    const handleSubmit = async (event) => {
        setFormError(false);
        if (!ValidateEmail(email)) {
            console.log("Invalid email");
            setFormError(true);
            return;
        }
        setInProgress(true);

        console.log("Registering email: " + email)
        await fetch('https://u3mej0dqg2.execute-api.us-east-2.amazonaws.com/profiles', {
            method: 'POST',
            body: JSON.stringify({
                email: email,
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
        .then((response) => response.json())
        .then((data) => {
            console.log("Success!");
            console.log(data);
            setInProgress(false);
            setRegistered(true);
            setStatusText("Successfully registered profile for " + email + ". Make sure to check your spam folder! Head over to ");
            setSuccessful(true);
        })
        .catch((err) => {
            console.log(err.message);
            setInProgress(false);
            setRegistered(true);
            setSuccessful(false);
            setStatusText("Registration failed. Please try again later.");
        });
    };


    return (
        <>
            <Container sx={{marginY: 5, pb: 15}} maxWidth="md">
                <Typography sx={{color:"var(--palette-color-1)"}} variant="h2">
                    Register
                </Typography>
                <Typography sx={{color: "var(--palette-color-1)", marginY: 5}} variant="body1">
                    To register, just enter your HBS email. We will send a verification code to your email, which you'll use to submit a crush.
                    <br/><br/>
                    This ensures that other people can't submit crushes in your name. If you lose your verification code, you can just enter your email here again and we will email you your verification code again.
                    <br/><br/>
                    <b>Note: The email with your verification code might end up in your spam folder, so make sure you check there if you can't find it.</b>
                </Typography>
                <ThemeProvider theme={theme}>
                    <Box
                        sx={{
                            width: 300,
                            maxWidth: '100%',
                        }}
                    >
                        <EmailField hasError={formError} setEmail={setEmail} setFormError={setFormError} setRegistered={setRegistered}/><br/>
                        <SubmitButton statusText={statusText} inProgress={inProgress} registered={registered} successful={successful} handleSubmit={handleSubmit}/>
                    </Box>
                </ThemeProvider>
            </Container>
        </>
    );
}

export default RegisterPage;
