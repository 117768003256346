import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {CircularProgress, Grid, TextField, ThemeProvider} from "@mui/material";
import Link from "@mui/material/Link"
import Button from "@mui/material/Button";

import { createTheme } from '@mui/material/styles';
import {useState} from "react";
import Box from "@mui/material/Box";

const theme = createTheme({
    palette: {
        gradumate: {
            main: '#A51C30',
            light: '#FBFEF9',
            dark: '#001427',
            contrastText: '#FBFEF9',
        },
    },
});

function ValidateEmail(input) {
    input = input.toLowerCase()
    // let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let hbsRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@mba\d{4}\.hbs\.edu$/;
    let personalRegex = /^e\w{3}i\wr@g\w{4}\.\w{3}$/;
    return input.match(hbsRegex) || input.match(personalRegex)
}

function EmailField({ name, hasError, email, setEmail, setFormError, setRegistered }) {
    const handleEmailTyping = (event) => {
        setEmail(event.target.value)
        setFormError(false)
        setRegistered(false)
    }
    if (hasError) {
        if (email === "") {
            return <TextField fullWidth error id="standard-basic" label="HBS Email" variant="standard" margin="dense" color="gradumate" helperText="Required Field" onChange={handleEmailTyping}/>
        } else if (!ValidateEmail(email)) {
            return <TextField fullWidth error id="standard-basic" label="HBS Email" variant="standard" margin="dense" color="gradumate" helperText="Invalid Email" onChange={handleEmailTyping}/>
        }
    }
    return <TextField fullWidth id="standard-basic" label="HBS Email" variant="standard" margin="dense" color="gradumate" onChange={handleEmailTyping}/>
}

function VerificationField({ name, hasError, verificationCode, setVerificationCode, setFormError, setRegistered }) {
    const handleVerificationCodeTyping = (event) => {
        setVerificationCode(event.target.value)
        setFormError(false)
        setRegistered(false)
    }
    if (hasError && verificationCode === "") {
        return <TextField fullWidth error id="standard-basic" label="Verification code" variant="standard" margin="dense" color="gradumate" helperText="Required Field" onChange={handleVerificationCodeTyping}/>
    }
    return <TextField fullWidth id="standard-basic" label="Verification code" variant="standard" margin="dense" color="gradumate" onChange={handleVerificationCodeTyping}/>
}

function CrushField({ name, hasError, crush, setCrush, setFormError, setRegistered, email }) {
    const handleVerificationCodeTyping = (event) => {
        setCrush(event.target.value)
        setFormError(false)
        setRegistered(false)
    }
    if (hasError) {
        if (crush === "") {
            return <TextField fullWidth error id="standard-basic" label="Crush's HBS Email" variant="standard" margin="dense" color="gradumate" helperText="Required Field" onChange={handleVerificationCodeTyping}/>
        } else if (!ValidateEmail(crush)) {
            return <TextField fullWidth error id="standard-basic" label="Crush's HBS Email" variant="standard" margin="dense" color="gradumate" helperText="Invalid Email" onChange={handleVerificationCodeTyping}/>
        } else if (email === crush) {
            return <TextField fullWidth error id="standard-basic" label="Crush's HBS Email" variant="standard" margin="dense" color="gradumate" helperText="Can't crush on yourself" onChange={handleVerificationCodeTyping}/>
        }
    }
    return <TextField fullWidth id="standard-basic" label="Crush's HBS Email" variant="standard" margin="dense" color="gradumate" onChange={handleVerificationCodeTyping}/>
}

function SubmitButton({inProgress, statusText, registered, handleSubmit}) {
    if (registered) {
        return <><br/><div>{statusText}</div></>
    } else if (inProgress) {
        return <Button fullWidth disabled variant="contained" color="gradumate" onClick={handleSubmit} sx={{marginY: 5}}><CircularProgress /></Button>
    } else {
        return <Button fullWidth variant="contained" color="gradumate" onClick={handleSubmit} sx={{marginY: 5}}>Submit</Button>
    }
}

function Home() {
    const [inProgress, setInProgress] = useState(false)
    const [statusText, setStatusText] = useState("")
    const [registered, setRegistered] = useState(false)
    const [formError, setFormError] = useState(false)

    const [email, setEmail] = useState('')
    const [verificationCode, setVerificationCode] = useState('')
    const [crush, setCrush] = useState('')
    const handleSubmit = async (event) => {
        setFormError(false);
        if (!ValidateEmail(email)) {
            console.log("Invalid email");
            setFormError(true);
            return;
        }
        if (verificationCode === "") {
            console.log("Verification code was not provided");
            setFormError(true);
            return;
        }
        if (crush === "") {
            console.log("Crush email was not provided");
            setFormError(true);
            return;
        }
        if (!ValidateEmail(crush)) {
            console.log("Crush email is invalid");
            setFormError(true);
            return;
        }
        if (crush === email) {
            console.log("Tried to submit crush on themselves");
            setFormError(true);
            return;
        }

        setInProgress(true);

        console.log("Submitting crush for: " + email)
        await fetch('https://u3mej0dqg2.execute-api.us-east-2.amazonaws.com/crush', {
            method: 'POST',
            body: JSON.stringify({
                email: email,
                identifier: verificationCode,
                crush: crush
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
            .then((response) => {
                console.log(response);
                setInProgress(false);
                setRegistered(true);
                if (response.status === 201) {
                    let data = response.json()
                    console.log("Success!");
                    console.log(data);
                    setStatusText("Crush submitted. Let's hope the feeling is mutual!");
                } else if (response.status === 404) {
                    console.log("Couldn't find profile!");
                    setStatusText("Couldn't find your profile. Make sure you've registered and that you entered the verification code you received in your email.");
                } else if (response.status === 409) {
                    console.log("Crush already exists!");
                    setStatusText("You've already submitted this crush before.");
                } else if (response.status === 403) {
                    console.log("Reached limit.");
                    setStatusText("It seems like you've already reached your limit of five crushes. Now you'll just have to wait and see!");
                } else {
                    // let data = response.json()
                    // console.log("Internal error: " + data);
                    console.log("Internal error: " + response.text());
                    setStatusText("Something went wrong. Please try again later.");
                }
            })
            .catch((err) => {
                console.log(err.message);
                console.log("Failure!");
                setInProgress(false);
                setRegistered(true);
                setStatusText("Submission failed. Please try again later.");
            });
    };


    return (
        <>
            <Container sx={{marginY: 5}} maxWidth="md">
                <Grid container spacing={2}>
                    <Grid item md>
                        <Typography sx={{color: "var(--palette-color-1)"}} variant="h2">
                            GraduMate
                        </Typography>
                        <Typography sx={{color: "var(--palette-color-1)"}} variant="body1">
                            “You miss 100% of the shots you don’t take.”
                            <br/><br/>
                            With graduation just around the corner, seize the moment with GraduMate 🎓💛 Enter the names
                            of your top 5 crushes, and if they have you on their list too, it’s a match made for MBA
                            success! Don’t let this milestone pass without shooting your shot - enter your information
                            now and make your last week of business school truly unforgettable!
                            <br/><br/>
                            How it works:<br/>
                            Step 1: Click ‘<Link href="register">Register</Link>’ tab to enter your email for
                            verification<br/>
                            Step 2: Submit crushes below (using their HBS email address)<br/>
                            Step 3: Matches revealed 🩷 (but ONLY if you are a match = both submitted each other as a
                            crush)<br/>
                            <br/>
                            Submit your crushes before 5 PM on Thursday, April 25th (so matches are out before EC Final
                            Sendoff). <br/><br/><b>Make sure to spread the word! As more people participate, your chances of
                            matching with your crush goes up!</b>
                            <br/><br/>
                            See <Link href="faq">FAQ section</Link> for more details.
                        </Typography>
                    </Grid>
                </Grid>
                <Container sx={{marginY: 5, pb: 10}}>
                <Grid container spacing={2}>
                        <Grid item md>
                            <Typography sx={{color: "var(--palette-color-1)"}} variant="h4">
                                Submit Crush
                            </Typography>
                            <Typography sx={{color: "var(--palette-color-1)", marginY: 5}} variant="body1">
                                Enter your HBS email, your <Link href="register">verification code</Link> and the HBS email of your crush. We won't email them unless you both match!
                            </Typography>
                            <ThemeProvider theme={theme}>
                                <Box
                                    sx={{
                                        width: 400,
                                        maxWidth: '100%',
                                    }}
                                >
                                    <EmailField hasError={formError} email={email} setEmail={setEmail} setFormError={setFormError} setRegistered={setRegistered}/><br/>
                                    <VerificationField hasError={formError} verificationCode={verificationCode} setVerificationCode={setVerificationCode} setFormError={setFormError} setRegistered={setRegistered}/><br/>
                                    <CrushField hasError={formError} crush={crush} setCrush={setCrush} setFormError={setFormError} setRegistered={setRegistered} email={email}/><br/>

                                    <SubmitButton statusText={statusText} inProgress={inProgress} registered={registered} handleSubmit={handleSubmit}/>
                                </Box>
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                </Container>

            </Container>
        </>
    );
}

export default Home;