import '../App.css';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

function InstructionsPage() {
    return (
        <>
            <Container sx={{marginY: 5, pb: 15}} maxWidth="md">
                <Typography sx={{color:"var(--palette-color-1)"}} variant="h2">
                    Instructions
                </Typography>
                <Typography sx={{color: "var(--palette-color-1)"}} variant="body1">
                    <b>“How does it work?”</b><br/>
                    Follow the steps above and submit your crushes one at a time. Much like Tinder, the only time another person will be notified of one of your crushes is that person, if you
                    matched with them! 🥰
                    <br/><br/>
                    <b>“Will anyone other than me know the crushes I submitted?”</b><br/>
                    The only person will know any of your crushes is your crush themself, if you match with them! For
                    crushes you don’t match with, your submission will go in a black box and never be seen by anyone.
                    Your little secret 😉
                    <br/><br/>
                    <b>“How can I trust the integrity of this dataset and know my matches won’t be revealed?”</b><br/>
                    This is being run by an ally to the single community (aka an HBS partner who has ZERO skin in this
                    game). He has kindly offered to help out his single friends during fire sale season. 🔥
                    <br/><br/>
                    <b>“When do I need to submit my crushes by?”</b><br/>
                    Please submit your crushes by Thursday, April 25th. We want all match announcements to go out before
                    EC Final Sendoff to maximize the fun and maximize the chance for DFMOs. 🪩
                    <br/><br/>
                    <b>“Why do I only get 5 submissions?”</b><br/>
                    We do this to prevent someone from gaming the system and submitting everyone as a match, much like
                    if you swipe right on everyone and wait to see what comes back. No fun! 😮
                    <br/><br/>
                    <b>“Why am I still single?”</b><br/>
                    You tell us! Get out there and meet your match for the last chance dance, the fire sale, the
                    distressed asset auction, or whatever you business school people call it! 😛
                    <br/><br/>
                    <b>“Why haven't I received my verification code yet?”</b><br/>
                    Sometimes the email can end up in the Spam folder, so make sure to check there first. If it still hasn't arrived after 30 minutes, email gradumate2024@gmail.com and we can send it to you! 😬
                    <br/><br/>
                    <b>“Why haven't I matched with anyone?”</b><br/>
                    Don't worry! It's possible that your crush just hasn't participated yet. Since the match is only created if two people have crushes on each other, participation is important! Spreading the word, and getting other singles to participate is key to improving your chances! 😍
                </Typography>
            </Container>
        </>
    );
}

export default InstructionsPage;
