import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import Root from './pages/Root';
import reportWebVitals from './reportWebVitals';
import InstructionsPage from "./pages/InstructionsPage";
import RegisterPage from "./pages/RegisterPage";
import Home from "./pages/Home";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <Root/>,
        children: [
            {
                index: true,
                element: <Home/>
            },
            {
                path: "faq/",
                element: <InstructionsPage/>
            },
            {
                path: "register/",
                element: <RegisterPage/>
            }
        ]
    },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
