import '../App.css';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box'
import MenuBar from "../components/MenuBar";
import { Outlet } from "react-router-dom";

function Root() {
    return (
        <>
            <CssBaseline/>
            <Box sx={{
                backgroundColor: "var(--palette-color-3)",
                height: '100vh',
                width: '100vw'
            }}>
                <MenuBar/>
                <Outlet/>
            </Box>
        </>
    );
}

export default Root;
